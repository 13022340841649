import { useEffect, useContext } from 'react'
import { signIn, useSession, signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Layout from 'mad-mint/layouts/legendofrocks/login'
import MadMintThemeContext from 'mad-mint/contexts/MadMint'
import useDetectWindowMode, {
  WINDOW_MODE,
} from 'mad-mint/hooks/useDetectWindowMode'

const handleSignIn = () => signIn()
const handleSignOut = () => signOut()

export default function Home() {
  const { theme } = useContext(MadMintThemeContext)
  const router = useRouter()
  const { data: session, status } = useSession()
  const windowMode = useDetectWindowMode()
  const isMobile = [WINDOW_MODE['XSM'], WINDOW_MODE['SM']].includes(windowMode)

  useEffect(() => {
    if (session) {
      if (session.user.roles?.includes('admin')) {
        router.push(`/admin`)
      } else if (session.user.roles?.includes('creator')) {
        router.push(`/creator`)
      } else {
        router.push(`/dashboard`)
      }
    }
  }, [session, router])

  return (
    <Layout page="landing">
      <Head>
        <title>Premint</title>
          </Head>
          <div className='grid content-start md:content-center gap-9 lg:gap-10'>
            <div className="grid gap-3 md:gap-6 max-w-[800px]">
                <div className="text-4xl md:text-6xl text-[#E1E6E1] font-goldman-bold leading-tight 2xl:text-[80px]">
                    THE <span className="text-[#FF4BFE]">BORED SOCIAL CLUB</span> INVITES YOU
                </div>
                <div className="md:text-lg !leading-[1.4375] lg:text-[24px] text-[#D4D4D8]">By signing up for the premint, you&apos;ll have the unique opportunity to join the coveted whitelist and be among the very first to receive the highly sought-after BSC NFT membership passes. Additionally, signing up gives you the chance of winning exclusive physical giveaways, featuring luxurious Bored Social Club merchandise, exquisite fine jewellery, rare and coveted liquor, and other opulent surprises.</div>
            </div>
            <div>
                {status === 'loading' ? (
                <button className="button transition duration-200 hover:bg-[rgb(255_93_254)] px-6 py-4 text-[#010304] bg-[#FF4BFE] text-lg lg:text-xl 2xl:text-[22px] rounded-lg font-goldman-regular w-full md:w-auto">Loading ...</button>
              ) : status === 'authenticated' ? (
                <button className="button transition duration-200 hover:bg-[rgb(255_93_254)] px-6 py-4 text-[#010304] bg-[#FF4BFE] text-lg lg:text-xl 2xl:text-[22px] rounded-lg font-goldman-regular w-full md:w-auto" onClick={handleSignOut}>Logout</button>
              ) : (
                <button className="button transition duration-200 hover:bg-[rgb(255_93_254)] px-6 py-4 text-[#010304] bg-[#FF4BFE] text-lg lg:text-xl 2xl:text-[22px] rounded-lg font-goldman-regular w-full md:w-auto" onClick={handleSignIn}>JOIN THE WAITLIST</button>
              )}
            </div>
            </div>
          <style jsx global>{`
      `}</style>
    </Layout>
  )
}
